/* 
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 * 
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

html {
    font-family: Inter, Arial, Helvetica, sans-serif;
}

body {
  background-color: #F8FAFB !important;
}

a {
    text-decoration: none !important;
}

:root {
    --font-style-300: normal 300 10px/1.5 'Inter';
    --font-style-500: normal 500 10px/1.5 'Inter';
    --font-style-600: normal 600 12px/1.5 'Inter';
}

.total_power {
    position: relative;
    width: 80px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #78BB39;
}
.site-grey {
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    color: #CECECE !important;
}
.site {
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    color: #325182 !important;
}
.site:hover {
    color: #57bcdc !important;
}
.alarm {
    width: 40px;
    height: 40px;
    background-color: #FF5A5A;
    border-radius: 50%;
    position: relative;
}
.red {
    background: #FF5A5A;
}
.amber {
    background: #F3A944;
}
.grey {
    background: #EEEEEE;
}
.alarm .number {
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pipe {
    position: relative;
    top: 19px;
    font-size: 15px;
    background: #FFFFFF;
    border: 1px solid #4E4E4E;
    border-radius: 32px;
}
.progress-size {
    height: 14px;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
}
.posgress-td {
    padding: 0px 16px 25px 16px !important;
}
.power-borders {
    height: 8px;
    border-left: 0.5px solid #fff;
    border-right: 0.5px solid #fff;
    border-radius: 2px;
}
.power-borders-zero {
    height: 8px;
    border: var(--power-border);
    border-radius: 2px;
    padding: 0 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
}
.power-borders-zero.x::after {
    content: 'x';
    color: #FF5A5A;
}
.MuiTooltip-tooltipPlacementTop {
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 19px;
}
.utility {
    background: #C6C6C6;
    color: #C6C6C6;
}
.genset {
    background: #85B953;
    color: #85B953;
}
.solar {
    background: #A6E66A;
    color: #A6E66A;
}
.bess {
    background: #57BCDC;
    text-align: center;
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.stripped {
    background: repeating-linear-gradient(
        135deg,
        #57BCDC,
        #57BCDC 5px,
        #C1EAF7 2px,
        #C1EAF7 8px
    );
}
.uptime {
    width: 64px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #C7C7C7;
    position: absolute;
    margin: -10px auto;
}
.uptime-alert {
    color: #FF5A5A;
    background: rgba(255, 90, 90, 0.2);
    border-radius: 12px;
}
.grid {
    position: absolute;
    left: 248px;
    top: 176px;
    width: 1016px;
}
.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 8px;
}
table tbody td {
    border: none;
}
table thead th {
    /*border: none;
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #868686 !important;*/
}

.font {
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #868686 !important;
}
.dashboard {
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 20px !important;
    line-height: 19px !important;

    display: flex;
    align-items: center;

    color: #868686;
}
.dashboard-site {
    width: 80%;
    height: 19px;
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 24px !important;

    display: flex;
    align-items: center;

    color: #325182;
}
.line {
    border: 1px solid #E3E3E3;
    margin-top: 1em;
    margin-bottom: 1em;
}

.spacer {
    margin-top: 1em;
    margin-bottom: 1em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-div {
    margin: 4em 4em;
}

thead.MuiTableHead-root tr th,
.dashboard,
.line {
    background: #F8FAFB;
}
.MuiToolbar-root {
    min-height: 0px;
}

table.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0em 0.4em;
}
th.MuiTableCell-root {
    border-bottom: 0px;
}  
td.MuiTableCell-root {
    /*border-bottom: 0px solid #E1E2E4 !important;*/
    background: #FFFFFF;
    padding: 11px;
}
td.background.MuiTableCell-root {
    border-bottom: 0px solid #E1E2E4 !important;
    background: #FAFAFA;
}
tr.MuiTableRow-root.tr-display td {
    border-bottom: 1px solid #E1E2E4 !important;
    border-top: 1px solid #E1E2E4;
}
tr.MuiTableRow-root.tr-display td:last-child {
    border-right: 1px solid #E1E2E4;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
tr.MuiTableRow-root.tr-display td:first-child {
    border-left: 1px solid #E1E2E4;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.before-collapse {
    background: #fff;
    margin-top: -10px;
    border-left: 1px solid #E1E2E4;
    border-right: 1px solid #E1E2E4;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: 0;
}
.after-collapse {
    padding: 1em;
    border-bottom: 1px solid #E1E2E4;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
td.table-collapse.MuiTableCell-root {
    padding: 0;
}

/* TOPBAR AND NAVBAR */
div.MuiContainer-root {
    background-color: white;
    height: 48px;
    max-width: 100% !important;
}
div.MuiAvatar-root {
    width: 32px;
    height: 32px;
}
ul.MuiMenu-list {
    overflow: hidden;
}
/*.profile-menu {
    background-color: #fff !important;
    border-radius: 5px !important;
    border: 1px solid #E1E2E4 !important;
    width: 128px !important;
    height: 37.44px !important;
    z-index: 999;
}*/
/*.profile-menu::before {
    content: "";
    position: absolute;
    border-left: 1px solid #E1E2E4 !important;
    border-top: 1px solid #E1E2E4 !important;
    top: -6px;
    right: 20px;
    width: 11px;
    height: 11px;
    background: #fff;
    transform: rotate(45deg);
}*/
.profile-menu-base::before {
    content: "";
    position: absolute;
    border-left: 1px solid #E1E2E4 !important;
    border-top: 1px solid #E1E2E4 !important;
    top: 11px;
    right: 20px;
    width: 11px;
    height: 11px;
    background: #fff;
    transform: rotate(45deg);
    z-index: 999;
}
.li-text {
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    display: flex;
    align-items: center !important;
    color: #868686 !important;
}
div.MuiToolbar-gutters {
    width: 100px;
    left: -8px;
    top: -9px;
}
li.MuiListItem-root {
    padding-bottom: 4px !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    left: unset !important;
    right: 1.0em;
}

/* NAVBAR BUTTONS */
.sidebar-menu {
    width: 48px;
    height: 48px;
    border-radius: 5px !important;
}
.active {
    background-color: #395D96;
}
.inactive {
    background-color: #325182;
}
.inactive-icon {
    background-color: #7F99C2;
}
svg.MuiSvgIcon-fontSizeMedium {
    width: 16px;
    height: 16px;
}

/* Authentication */
.login-container {
    background-color: #fff;
    width: 480px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.centerpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo {
    position: absolute;
    top: 29%;
    transform: translateY(-40%);
    width: 160px;
    height: 26.67px;
}
.logo path {
    fill: #325182;
}
.signin {
    position: absolute;
    top: 40%;
    transform: translateY(-45%);
    width: 164px;
    height: 24px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 19px;
    /* or 96% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #868686;
}
.signin-button {
    margin: 0 auto;
    mix-blend-mode: multiply;
    position: absolute;
    top: 58%;
}
.expired {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #868686;
    position: absolute;
    margin-top: 375px;
}
.S9gUrf-YoZ4jf > iframe {
    margin: -17px 53px !important;
}
.center-page {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Alerts */
.banner {
    box-sizing: border-box !important;
    /*height: 40px !important;*/
    border-radius: 5px !important;
    padding: 2px 16px !important;
}
.banner-error {
    background-color: #FAF0EA !important;
    border: 1px solid #FF5A5A !important;
}
.banner-warning {
    background-color: #FEF7EA !important;
    border: 1px solid #FEBF21 !important;
}
.banner-info {
    background-color: #E7EEFA !important;
    border: 1px solid #3087E9 !important;
}
.banner-success {
    background-color: #F1F9F4 !important;
    border: 1px solid #51DC6B !important;
}

/* General Chart Graph and Site Diagram */
.graph-title{
    position: absolute;
    color: #325182;
    font: var(--font-style-500);
    font-size: 14px;
    line-height: var(--graph-line-height);
    margin-left: 20px;
}

/* Chart Graps */
.chart-graph {
    width: 100%;
    background: #fff;
    border: 1px solid #E1E2E4;
    border-radius: 5px;
    padding-bottom: 2em;
}
.tooltip-font {
    font-family: Inter, Arial, Helvetica, sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    align-items: center !important;
}
.tooltip-label {
    font-size: 12px;
    line-height: 14px;
}
.tooltip-color {
    display: inline-block;
    width: 11px !important;
    height: 11px !important;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
}
.load {
    background: #444444;
}
.tooltip-type {
    padding-left: 8px !important;
    font-size: 10px !important;
    line-height: 12px !important;
    color: #6C6C6C;
}
.tooltip-alarm-type {
    font-size: 10px !important;
    line-height: 12px !important;
    color: #6C6C6C;
}
.tooltip-value {
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12px !important;
    color: #000000;
}
.css-tlelie-MuiListItemText-root {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

/* Custom Legend */
.legend-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 6px;
    cursor: pointer;
    user-select: none;
}
  
/* Hide the browser's default checkbox */
.legend-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 4px;
    left: 8px;
    height: 18px;
    width: 18px;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.legend-checkbox:hover input ~ .checkmark {
    background-color: #FA9E57;
}

/* When the checkbox is checked, add a blue background */
.legend-checkbox input:checked ~ .checkmark {
    background-color: #FA9E57;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.legend-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.legend-checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: var(--checkmark-border, 1px solid #fff);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    margin-left: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
}
.css-j204z7-MuiFormControlLabel-root {
    /*align-items: flex-start !important;*/
}
.legend {
    width: 17px;
    height: 17px;
    border-radius: 2px;
    margin-right: 10px;
    border: 1px solid #979797;
}
.legend-row {
    display: flex;
    align-items: center;
}
.legend-text {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #000;
    margin-right: 10px;
}
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
    stroke-opacity: 0;
}

/* Site Diagram */
.site-diagram {
    width: 100%;
    height: 320px;
    background: #fff;
    border: 1px solid #E1E2E4;
    border-radius: 5px;
}
.custom-node {
    background: #fff;
    padding-left: 5px;
    padding-right: 5px;
    width: 72px;
    height: 72px;
    border: var(--border-custom-node);
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    color: #868686;
}
.node-no-childs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 13px;
}
.node-source {
    font: var(--font-style-300);
    color: var(--node-source-color);
}
.node-data {
    font: var(--font-style-500);
}
.react-flow__panel {
    display: none !important;
}
.react-flow__pane {
    cursor: default !important;
}
.assembly-icon {
    width: 22px;
    height: 22px;
    margin-top: 0px;
}
.large-assembly-icon path {
    stroke: var(--assembly-icon-color);
}
.assembly-icon path {
    stroke: var(--assembly-icon-color);
}
.vertical-progress-bar {
    transform: rotate(270deg);
    height: 8px !important;
    width: 53px;
    border: 1px solid var(--node-source-color);
    border-radius: 1px !important;
    margin-left: 22px;
    margin-top: 27px;
    position: absolute;
}  

/* Dropdown Energy */
.css-o8mwn3-MuiButtonBase-root-MuiListItemButton-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    top: 0px !important;
}
.MuiCollapse-root {
    /*border: 1px solid #E1E2E4 !important;*/
    /*border-radius: 4px !important;*/
    background: #FFF !important;
}
.collapse-root-with-shadow {
    box-shadow: 2px 2px 20px 0px #0000000F;
    background: #FFF !important;
}
.collapse-root-with-border {
    border: 1px solid #E1E2E4 !important;
    border-radius: 4px !important;
    background: #FFF !important;
}
.select-text {
    color: #6C6C6C;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.stat-box {
    border-radius: 5px;
    border: 1px solid #E1E2E4;
    width: 230px;
    height: 215px;
    background-color: white;
}
.sms-component {
    border-radius: 5px;
    background-color: white;
    border: 1px solid #E1E2E4;
}
.map-popover {
    border-radius: 5px;
    background-color: white;
    border: 1px solid #E1E2E4;
    padding: 1em;
}
.legend-timezone {
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    bottom: 19px;
    left: 0px;
}
.date-range-menu{
    font-style: 'normal' !important;
    font-size: 12px !important;
}
.MuiTab-iconWrapper {
   color: '#7f99c2';
}
.MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: 'white';
}
.MuiTabs-flexContainer {
    align-items: 'center';
}
.generic-panel {
    width: 100%;
    background: #fff;
    border: 1px solid #E1E2E4;
    border-radius: 5px;
    padding: 2em;
    min-height: 150px; 
}
.settings-panel {
    width: 100%;
    padding: 2em;
}

.recharts-wrapper {
    position: absolute;
}

.rmdp-calendar {
    border: 1px solid #E1E2E4;
    border-radius: 5px;
}

.mapboxgl-popup-content {
    padding: 0px !important;
}

/*
.rmdp-week-day {
    color: #325182 !important;
    border-color: "#325182";
}

.rmdp-arrow {
    border: solid #325182 !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-range .rmdp-range-hover {
    color: #325182 !important;
}

.rmdp-range-hover span:hover {
    color: #325182 !important;
}
*/